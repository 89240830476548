import React, { FunctionComponent, useEffect } from "react";
import { RouteComponentProps } from "@reach/router";
import GatsbyImage, { FluidObject } from "gatsby-image";

import { graphql } from "gatsby";

import { Link, useIntl } from "gatsby-plugin-intl";

import Button from "../components/Button";
import Layout from "../components/Layout";
import TextCard from "../components/TextCard";
import RedFeature from "../components/RedFeature";
import SEO from "../components/SEO";
import styles from "../styles/pages/index.module.scss";

export const query = graphql`
  query {
    mainBackgroundImage: file(
      relativePath: { eq: "pages/index/main-background.png" }
    ) {
      publicURL
      childImageSharp {
        fluid(quality: 90) {
          src
          tracedSVG
        }
      }
    }
    yellowDots: file(relativePath: { eq: "backgrounds/yellow-dots.svg" }) {
      publicURL
    }
    yellowSquares: file(
      relativePath: { eq: "backgrounds/yellow-squares.svg" }
    ) {
      publicURL
    }
    coupleEating: file(
      relativePath: { eq: "pages/index/illustration-couple-eating.svg" }
    ) {
      publicURL
    }
    phoneImage: file(relativePath: { eq: "pages/index/phone.png" }) {
      publicURL
    }
    appStoreBadge: file(relativePath: { eq: "badges/app-store.svg" }) {
      publicURL
    }
    googlePlayBadge: file(relativePath: { eq: "badges/google-play.svg" }) {
      publicURL
    }
    joinUsBackgroundImage: file(
      relativePath: { eq: "pages/index/join-us-background.png" }
    ) {
      publicURL
    }
    moneyBadge: file(relativePath: { eq: "badges/money.svg" }) {
      publicURL
    }
    calendarBadge: file(relativePath: { eq: "badges/calendar.svg" }) {
      publicURL
    }
    confortBadge: file(relativePath: { eq: "badges/confort.svg" }) {
      publicURL
    }
  }
`;
interface ImageFluid {
  publicURL: string;
  childImageSharp: {
    fluid: FluidObject;
  };
}
interface IndexPageProps extends RouteComponentProps {
  data: {
    mainBackgroundImage?: ImageFluid;
    yellowDots?: { publicURL: string };
    coupleEating?: { publicURL: string };
    phoneImage?: { publicURL: string };
    yellowSquares?: { publicURL: string };
    appStoreBadge?: { publicURL: string };
    googlePlayBadge?: { publicURL: string };
    joinUsBackgroundImage?: { publicURL: string };
    moneyBadge?: { publicURL: string };
    calendarBadge?: { publicURL: string };
    confortBadge?: { publicURL: string };
  };
}
const IndexPage: FunctionComponent<IndexPageProps> = ({ data }) => {
  const intl = useIntl();
  const renderFeatureCards = (cards) => {
    return cards.map((item, index) => (
      <div key={`text-card-item-${index}`} className={styles.card}>
        <TextCard>
          <RedFeature
            title={intl.formatMessage({ id: item.title })}
            text={intl.formatMessage({ id: item.subtitle })}
            icon={item.icon}
            alt={item.alt}
          />
        </TextCard>
      </div>
    ));
  };
  const cards = [
    {
      title: "indexPage_featureSection_keyFeature_one_title",
      subtitle: "indexPage_featureSection_keyFeature_one_text",
      icon: data.moneyBadge?.publicURL,
      alt: "money badge",
    },
    {
      title: "indexPage_featureSection_keyFeature_two_title",
      subtitle: "indexPage_featureSection_keyFeature_two_text",
      icon: data.calendarBadge?.publicURL,
      alt: "calendar badge",
    },
    {
      title: "indexPage_featureSection_keyFeature_three_title",
      subtitle: "indexPage_featureSection_keyFeature_three_text",
      icon: data.confortBadge?.publicURL,
      alt: "confort badge",
    },
  ];

  return (
    <Layout transparentHeader>
      <SEO
        title={intl.formatMessage({ id: "seo_title_index" })}
        description={intl.formatMessage({ id: "seo_description_index" })}
        keywords={intl.formatMessage({ id: "seo_keywords_index" })}
        canonical_url={`https://take-eat.pt/`}
        image={`https://take-eat.pt${data.phoneImage?.publicURL}`}
      />
      <div className={styles.firstSection}>
        <div className={styles.content}>
          <h1>{intl.formatMessage({ id: "indexPage_welcome_title" })}</h1>
          <p>{intl.formatMessage({ id: "indexPage_welcome_subtitle" })}</p>
          {/* <form>
            <input type="text" name="" id="" />
            <button>→</button>
          </form> */}
          {/* <span>
            {intl.formatMessage({ id: "indexPage_welcome_serchLabel" })}
          </span> */}
        </div>
        {/* <img src={data.mainBackgroundImage?.publicURL} alt="" /> */}
        <GatsbyImage
          fadeIn
          alt=""
          fluid={data.mainBackgroundImage?.childImageSharp.fluid}
          loading="lazy"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: -1,
          }}
        />
      </div>
      <div className={styles.secondSection}>
        <div className={styles.centered}>
          <h2 className={styles.howItWorksTitle}>
            {intl.formatMessage({ id: "indexPage_featureSection_title" })}
          </h2>
          <p className={styles.howItWorksParagraph}>
            {intl.formatMessage({ id: "indexPage_featureSection_subTitle" })}
          </p>
        </div>
        <div className={styles.featureCardList}>
          {renderFeatureCards(cards)}
        </div>
        <div className={styles.dotsImg}>
          <img src={data.yellowDots?.publicURL} alt="yellow dots image" />
        </div>
        <div className={styles.coupleImg}>
          <img src={data.coupleEating?.publicURL} alt="couples dinning image" />
        </div>
      </div>
      <div className={styles.thirdSection}>
        <div className={styles.imageContainer}>
          <img src={data.phoneImage?.publicURL} alt="phone with take-eat app" />
        </div>
        <div className={styles.content}>
          <h2>{intl.formatMessage({ id: "indexPage_storesSection_title" })}</h2>
          <p>
            {intl.formatMessage({ id: "indexPage_storesSection_subTitle" })}
            <Link to={"/contactos"}>
              {intl.formatMessage({
                id: "indexPage_storesSection_subTitle_Link",
              })}
            </Link>
          </p>
          {/* <img src={data.googlePlayBadge?.publicURL} alt="play store logo" />
          <img src={data.appStoreBadge?.publicURL} alt="apple store logo" /> */}
        </div>
      </div>
      <div className={styles.fourthSection}>
        <div className={styles.yellowSquares}>
          <img
            src={data.yellowSquares?.publicURL}
            alt=" yellow squares pattern "
          />
        </div>
        <div className={styles.content}>
          <img
            src={data.joinUsBackgroundImage?.publicURL}
            alt="background image"
          />
          <div className={styles.card}>
            <TextCard>
              <h3>
                {intl.formatMessage({ id: "indexPage_bottomSection_title" })}
              </h3>
              <p>
                {intl.formatMessage({ id: "indexPage_bottomSection_subTitle" })}
              </p>
              <div className={styles.joinButton}>
                <Link
                  style={{ color: "white", textDecoration: "none" }}
                  to="/registo"
                >
                  <Button primary>
                    {intl.formatMessage({
                      id: "indexPage_bottomSection_button_label",
                    })}
                  </Button>
                </Link>
              </div>
            </TextCard>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default IndexPage;
